import React, {  useEffect } from "react";
import { Col ,Row} from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import profile from "../../../assets/Images/Team/team3.webp";
export default function OurTeam() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container" id="formateur">
        <div className="my-5">
          <h2 className="nosformateurs text-center">Notre Formateur</h2>
        </div>
        <Row className="justify-content-center mt-3 mb-5">
              <Col xl={4} lg={4} md={4}>
              <div class="bimg">
                <img src={profile} className='card-img img-fluid mx-auto' alt='Nassef Hmimda' />
              </div>
              </Col>
              <Col xl={6} lg={6} md={6}>
                <h2 className="nameprof">NASSEF HMIMDA</h2>
                <hr className="my-5"></hr>
                <p>
                Docteur de l’Ecole Centrale Paris en Management Stratégique des Technologies et 
                de l’Innovation, Nassef HMIMDA y enseigne le management depuis plus de 15 ans. 
                Son parcours académique l’a conduit à enseigner l’innovation et la stratégie aussi 
                bien dans des écoles de commerce que dans des écoles d’ingénieurs.
                Nassef HMIMDA est par ailleurs Directeur de l’Exed CentraleSupelec à Casablanca 
                et responsable pédagogique de l’EXEC Mastère Spécialisé Leadership et Projets 
                Innovants de CentraleSupélec
                </p>
                <div className="text-end">
                <a href="https://fr.linkedin.com/in/nassef-hmimda-phd-58149a" className="btn-envoyer w-80 showmore ourSurvice">Voir plus</a>
                </div>
              </Col>
        </Row>
       
      </div>
    </>
  );
}
