import React from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        errorfullname: '',
        errorphone: '',
        erroremail: '',
        errorfonction: '',
        errorville: '',
        success:''

    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({errorfullname: ''});
    this.setState({errorphone: ''});
    this.setState({erroremail: ''});
    this.setState({errorfonction: ''});
    this.setState({errorville: ''});
    //Validation 
    if(target.nomcomplet.value ==''){
      return this.setState({errorfullname: 'nom complet est obligatoire'});
    }
    if(target.fonction.value ==''){
      return this.setState({errorfonction: 'fonction est obligatoire'});
    }
    if(target.phone.value ==''){
      return this.setState({errorphone: 'téléphone est obligatoire'});
    }
    if(target.email.value ==''){
      return this.setState({erroremail: 'email est obligatoire'});
    }
   
    if(target.ville.value ==''){
      return this.setState({errorville: 'Ville est obligatoire'});
    }
        const lead={
        'fullname':target.nomcomplet.value,
        'phone':target.phone.value,
        'email':target.email.value,
        'fonction':target.fonction.value,
        'ville':target.ville.value,
      } 
      this.setState({success: 'Merci de nous avoir contacté, nous avons bien reçu vos informations'});
      target.reset();
      window && window.dataLayer && window.dataLayer.push({
        'event':'form_submit_success',
        'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
    });
  }
  render() {
    return (
      <div className="aboutUsBannerImg" id="contact">
      <div className="container">
        <div className="contactUs">
          <Row>
            <Col xl={6} md={6} lg={6} sm={12}>
              <div className="titleFont">
                <h3>Contactez nous</h3>
                <h2>Je suis intéressé</h2>
              </div>
              <form className="row g-3 needs-validation formContactus" method="POST" onSubmit={this.handleSubmit}>
                <div className="col-md-12 col-sm-12 col-12">
                {this.state.success
                  ? <div className='text-success px-5 text-center fs-3'> {this.state.success} </div>
                  :
                  ''
                }
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    name="nomcomplet"
                    placeholder="Nom & Prénom*"
                  />
                  {this.state.errorfullname
                  ? <span className='text-danger px-1'> {this.state.errorfullname} </span>
                  : ''
                }
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    name="fonction"
                    placeholder="Fonction*"
                    
                  />
                     {this.state.errorfonction
                  ? <span className='text-danger px-1'> {this.state.errorfonction} </span>
                  : ''
                }
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    placeholder="Téléphone*"
                    name="phone"
                    
                  />
                  {this.state.errorphone
                  ? <span className='text-danger px-1'> {this.state.errorphone} </span>
                  : ''
                }
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom04"
                    placeholder="Email*"
                    name="email"
                  />
                   {this.state.erroremail
                  ? <span className='text-danger px-1'> {this.state.erroremail} </span>
                  : ''
                }
                </div>  
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom05"
                    placeholder="Ville*"
                    name="ville"
                  />
                   {this.state.errorville
                  ? <span className='text-danger px-1'> {this.state.errorville} </span>
                  : ''
                }
                </div>
                

                <div className="col-12 text-center">
                <button className="ourSurvice w-80">Réserver votre place </button>
                </div>
              </form>
            </Col>
            <Col xl={6} md={6} lg={6} sm={12}>
              <div className="quickContact py-5">
                <h6 className="fw-bold titlecontact">Un accompagnement durant toute l’expérience pédagogique : </h6>
                <ul>
                  <li>Accompagnement méthodologique</li>
                  <li>Accompagnement par les outils</li>
                  <li>Accompagnement par un facilitateur dédié aux groupes de travail.</li>
                </ul>
                <h6 className="fw-bold titlecontact">Une méthodologie robuste et des outils adaptés et conçus pour le processus apprenant : </h6>
                <ul>
                  <li>UX design</li>
                  <li>Prototypage</li>
                  <li>Captation vidéo</li>
                  <li>Cloud collab</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    );
  }
}

export default Banner;