import React from "react";
import Slider from "react-slick";
import { Card, Col } from "react-bootstrap";
import ServicesCard1 from "../../../assets/Images/OurBestServices/PRODUCTION LIVRABLE.webp";
import ServicesCard2 from "../../../assets/Images/OurBestServices/ATELIER.webp";
import ServicesCard3 from "../../../assets/Images/OurBestServices/REALISATION.webp";
import ServicesCard4 from "../../../assets/Images/OurBestServices/CADRAGE DU SUJET.webp";
const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 1900,
  autoplay: true,
  autoplaySpeed: 700,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function OurBestServices() {
  return (
    <>
      <div className="ourBestServicesBanner" id="programme">
        <div className="container">
          <div className="ourBestServices text-center">
            <div className="titleFont">
              <h2 className="fs-2 maincolor">Processus </h2>
              <h2 className="fs-2">de déroulement de la formation.</h2>
            </div>
            <div className="ourBestServicesRow beffect">
            <Slider {...settings} className="row">
            <Col sm={3} xs={12}>
                  <Card data-aos="fade-up">
                  <div className="bimg">
                    <Card.Img
                      className="img-fluid mx-auto"
                      variant="top"
                      src={ServicesCard4}
                      alt='Cadrage du sujet'
                    />
                    </div>
                    <Card.Body>
                      <Card.Title>Cadrage du sujet</Card.Title>
                      <Card.Text>
                      <ul className="info-programme">
                          <li>
                          Entretiens pour cerner et comprendre la problématique que souhaite traiter 
                          l’entreprise
                          </li>                          
                        </ul>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
            <Col sm={3} xs={12}>
                  <Card data-aos="fade-up">
                  <div className="bimg">
                    <Card.Img
                      className="img-fluid mx-auto"
                      variant="top"
                      src={ServicesCard3}
                      alt='Réalisations et productions dédiées pour l’atelier'
                    />
                    </div>
                    <Card.Body>
                      <Card.Title>Réalisations et productions dédiées pour l’atelier </Card.Title>
                      <Card.Text>
                      <ul className="info-programme">
                          <li>
                          Impact humain et organisationnel
                          </li>
                          <li>
                          Réalisation d’étude et de benchmark à diffuser lors 
                            de l’atelier pour inspirer les participants
                          </li>
                        </ul>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3} xs={12}>
                  <Card data-aos="fade-up">
                  <div className="bimg">
                    <Card.Img
                      className="img-fluid mx-auto"
                      variant="top"
                      src={ServicesCard2}
                      alt="Déroulé de l’atelier"
                    />
                    </div>
                    <Card.Body>
                      <Card.Title>Déroulé de l’atelier </Card.Title>
                      <Card.Text>
                      <ul className="info-programme">
                          <li>
                          Les organisations dirigées 
                          </li>
                          <li>
                          Animation par les intervenants 
                          et facilitateur durant les 2 jours
                          </li>
                        </ul>
                      </Card.Text>
                     
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3} xs={12}>
                  <Card data-aos="fade-up">
                    <div className="bimg">
                      <Card.Img
                        className="img-fluid mx-auto"
                        variant="top"
                        src={ServicesCard1}
                        alt='Production d’un livrable innovant'
                      />
                    </div>
                    <Card.Body>
                      <Card.Title>
                        Production d’un livrable innovant
                        </Card.Title>
                      <Card.Text>
                        <ul className="info-programme">
                          <li>
                          Impact du digital sur les entreprses
                          </li>
                          <li>
                          Au bout de 2 jours d’atelier chaque 
                          sous-groupe aura produit un prototype 
                          de son concept + une vidéo de 
                          présentation de son concept
                          </li>
                        </ul>
                      </Card.Text>
                     
                    </Card.Body>
                  </Card>
                </Col>
             
                
              
              </Slider>
              <Col sm={4} xs={12} className="mx-auto mt-5">
                      <a href="#contact" className="homeBannerBtn ourSurvice w-100 d-block">Réserver votre place</a>
                </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
