import React, { useEffect } from "react";
import { Container,Row, Col, } from "react-bootstrap";
import img1 from "../../../assets/Images/OnlineLearing/OLearing.webp";
import AOS from "aos";
import "aos/dist/aos.css";

export const Innovation = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  });
  return (
    <>
      <div className="Title my-5">
        <Container>
          <div className="Title">
            <Row className="OLearing-section">
              <Col xl={6} lg={6} md={6} sm={6}>
                <div
                  className="Online-learnig-Content text-align-center"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                  onScroll="AOS"
                >
                  <h2>Qu’est-ce que les Innovation Days ?</h2>
                  <p>
                  Un parcours apprenant sur 2 jours dans un lieu dédié à 
                  l’innovation.
                  Une expérience pédagogique unique pour explorer et vivre un 
                  processus d’innovation de la conception jusqu’à la 
                  matérialisation par du prototypage rapide.
                  Pas de prérequis nécessaire pour les stagiaires de la 
                  formation pour suivre et vivre pleinement cette expérience.
                  </p>
                  <h2 className="pt-5">Promesse pédagogique</h2>
                  <p>
                  Vivre les différentes étapes d’un processus d’innovation.
                  Se rendre compte des différentes compétences, attitudes et mindset nécessaires pour réaliser et conduire un projet d’innovation.
                  Learning by doing : réaliser au bout des 2 jours de formation un prototype innovant sur le sujet de votre choix.
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6}>
                <img src={img1} alt="OLearing" className="mx-auto img-fluid" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};