import React from "react";
import Banner from "../HomePage/Banner"
import OurBestServices from "./OurBestServices";
import OurTeam from "./OurTeam";
import ContactUs from "./ContactUs";
import {Innovation} from "./Innovation";
export default function Home() {
  return (
    <>
      <Banner/>
      <Innovation />
      <OurBestServices/>
      <OurTeam/>
      <ContactUs/>
    </>
  );
}
